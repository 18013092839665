<template>
  <search
    :document-types="['invoices']"
    :scope="scope"
    hide-document-tabs
    hide-actions
    use-responsive-search-field
    @row-action="handleRowAction"
    @hook:mounted="setExcludeDocumentIds"
    ref="search"
  />
</template>
<script>
import Scope from '@/pages/search/controllers/scope'

export default {
  components: {
    Search: () => import('@/pages/search/components/search')
  },
  computed: {
    excludedDocumentIds () {
      return this.invoices.map(i => ({ id: i.id, type: 'invoices' }))
    },
    scope () {
      return Scope.promiseInvoicesAccount(this.promise.id, this.promise.accountId)
    }
  },
  methods: {
    handleRowAction (e) {
      if (e.action === 'addOrRemove') {
        const invoices = this.lodash.cloneDeep(this.invoices)
        const index = invoices.findIndex(i => i.id === e.invoice.id)
        if (index > -1) {
          invoices.splice(index, 1)
        } else {
          const invoice = this.lodash.cloneDeep(e.invoice.extra)
          this.$set(invoice, 'amountPromised', e.invoice.extra.promiseAmountAllowed)
          this.$set(invoice, 'id', e.invoice.id)
          invoices.push(invoice)
        }
        this.$emit('update:invoices', invoices)
      }
    },
    setExcludeDocumentIds () {
      this.$refs.search?.search?.chain(s => s.searchedIds.clear().exclude(this.excludedDocumentIds)).execute()
    }
  },
  props: {
    promise: {
      type: Object,
      required: true
    },
    invoices: Array
  },
  watch: {
    invoices () {
      this.setExcludeDocumentIds()
    }
  }
}
</script>
